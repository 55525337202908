import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'cs-logo',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './logo.component.html',
  styleUrl: './logo.component.scss',
})
export class LogoComponent {
  @Input() classes: string | undefined;
  @Input() simple: boolean | undefined;
  @Input() size: 'lg' | 'md' | 'sm' = 'md';

  get logoUrl() {
    return this.simple ? '/assets/images/logos/casuals-logo-simple.png' : '/assets/images/logos/casuals-logo.png';
  }
}
